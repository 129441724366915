<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="12" lg="12" cols="12">
          <b-card-actions
              class="list-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
              v-if="!isNewReservastion"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-flex justify-content-center card-title mb-50">Özet Görünüm</h4>
                </b-col>
              </div>
            </b-row>
            <b-row class="mt-3">
              <b-col md="6">
                <b-form-group class="d-inline-block mr-1">
                  <h6 class="font-weight-bold">
                    Başlangıç Tarihi
                  </h6>
                  <flat-pickr
                      v-model="start"
                      class="form-control"
                  />
                </b-form-group>
                <b-form-group class="d-inline-block">
                  <h6 class="font-weight-bold">
                    Bitiş Tarihi
                  </h6>
                  <flat-pickr
                      v-model="end"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-tabs class="mt-50" v-model="activeTab">
                  <b-tab v-for="(item,i) in salonlar" :title="item.salonAdi" :key="item.salonID"
                         @click="count++"
                  >
                    <hr/>
                    <full-calendar
                        :ref="'rezCalendar-'+ i"
                        :options="calendarOptions"
                        class="full-calendar"
                        :key="count"
                    >
                      <template v-slot:eventContent="val">
                        <b>{{ val.event.extendedProps.cariUnvan }}</b>
                      </template>
                    </full-calendar>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card-actions>
          <yeni-rezervasyon v-if="isNewReservastion" :show="isNewReservastion" @dataReturned="handleDataReturned"
                            :selectDate="selectedDate"
          />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BTab,
  BTabs,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import YeniRezervasyon from '@/views/salon/yeniRezervasyon.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    YeniRezervasyon,
    flatPickr,
    FullCalendar,
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    listPlugin,
    BTab,
    BTabs,
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      selectedDate: null,
      isNewReservastion: false,
      start: '',
      end: '',
      count: 0,
      activeTab: 0,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        locale: 'tr',
        height: 750,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        initialView: 'dayGridMonth',
        dateClick: this.dateClick,
        eventClick: this.eventClick,
        eventDrop: this.eventDrop,
        eventClassNames: this.eventClassNames,
        datesSet: this.dateSet,
        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        fixedWeekCount: false,
        dayMaxEvents: 3,
        moreLinkText: 'Daha fazla etkinlik',
        noEventsContent: 'Etkinlik Bulunamadı',
        buttonText: {
          today: 'Bugün',
          month: 'Aylık',
          week: 'Haftalık',
          day: 'Günlük',
          list: 'Liste',
        },
        allDayText: 'Tüm Gün',
        navLinks: true,
        calendarsColor: {
          '1': 'warning',
          '2': 'success',
          '3': 'danger',
        },
        eventColor: '#378006',
        events: this.getEvent(),
      },
    }
  },
  watch: {
    start: function (newVal) {
      this.dateSet()
    },
    end: function (newVal) {
      this.dateSet()
    },
  },
  computed: {
    ilkGorunenSalonID() {
      return this.salonlar[this.activeTab]?.salonID
    },
    salonlar() {
      return Object.values(store.getters.SALON_LISTESI_GET)
    },
    ozetGorunum() {
      return Object.values(store.getters.OZET_GORUNUM_GET)
    },
  },
  methods: {
    handleDataReturned(data) {
      data == false ? this.isNewReservastion = false : true
    },
    dateClick(val) {
      const t = this
      this.$root.$emit('bv::toggle::collapse', 'rez-date')
      t.selectedDate = val.dateStr
      t.isNewReservastion = true
    },
    eventClick(val) {
      router.push({
        name: 'expariedLogin',
      })
      router.push({
        name: 'rezervasyonDetay',
        params: { id: val.event.extendedProps.rezervasyonID },
      })
    },
    dateSet() {
      setTimeout(() => {
        const self = this
        const data = {
          crm_token: localStorage.getItem('buroUserToken'),
          serviceName: 'salon',
          methodName: 'rezervasyonListesiSearch',
          baslangic: 0,
          salonID: this.ilkGorunenSalonID,
          [this.start ? 'start' : '']: this.start || '',
          [this.end ? 'end' : '']: this.end || '',
        }
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            store.commit('setToken', res.userToken)
            const calendarApi = self.$refs[`rezCalendar-${self.activeTab}`][0].getApi()
            calendarApi.getEventSources()
                .forEach(source => {
                  source.remove()
                })
            const yeniVeri = []
            Object.values(res.liste)
                .forEach(item => {
                  const event = {
                    cariUnvan: item.cariUnvan,
                    baslangic: item.girisSaat,
                    bitis: item.cikisSaat,
                    start: item.girisSaat,
                    end: item.cikisSaat,
                    salonAdi: item.salonAdi,
                    salonID: item.salonID,
                    rezervasyonID: item.rezervasyonID,
                    tarifeID: item.tarifeID,
                    toplamTutar: item.toplamTutar,
                    rezervasyonNotu: item.rezervasyonNotu,
                    kiraTutari: item.kiraTutari,
                    opsiyonTutari: item.opsiyonTutari,
                    cariKartID: item.cariKartID,
                  }
                  yeniVeri.push(event)
                })
            calendarApi.addEventSource(yeniVeri)
          },
          dataType: 'json',
        })
      })
    },
    eventClassNames(val) {
      const color = this.calendarOptions.calendarsColor[val.event._def.extendedProps.tarifeID]
      return `bg-light-${color} border border-${color}`
    },
    eventDrop(val) {
      this.$swal({
        title: 'Tarihi değiştirmek istediğinize eminmisiniz?',
        text: 'Saat aynı kalır. Sadece Tarih değişir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const startHour = val.event._def.extendedProps.baslangic.slice(11, 19)
              const endHour = val.event._def.extendedProps.bitis.slice(11, 19)
              const veri = val.event._instance.range.start.toLocaleString()
                  .slice(0, 10)
              const day = veri.slice(0, 2)
              const mounth = veri.slice(3, 5)
              const year = veri.slice(6, 10)
              const fullTimeStart = `${year}-${mounth}-${day} ${startHour}`
              const fullTimeEnd = `${year}-${mounth}-${day} ${endHour}`
              const fd = new FormData()
              fd.append('crm_token', store.getters.TOKEN_GET)
              fd.append('serviceName', 'salon')
              fd.append('methodName', 'salonRezervasyonDuzenle')
              fd.append('rezervasyonID', val.event._def.extendedProps.rezervasyonID)
              fd.append('updateList[girisSaat]', fullTimeStart)
              fd.append('updateList[cikisSaat]', fullTimeEnd)
              axios.post(store.state.POST_URL, fd, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
                  .then((res, position = 'bottom-right') => {
                    if (res.data.result.status == 200) {
                      this.dateSet()
                      const mesaj = this.$store.getters.notificationSettings(res.data)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı',
                            text: mesaj,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                          },
                        }, { position })
                        store.commit('setToken', res.data.userToken)
                      } else {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'warning',
                          },
                        })
                      }
                    } else {
                      this.dateSet()
                    }
                  })
                  .catch(err => {
                    this.dateSet()
                    const mesaj = this.$store.getters.notificationSettings(err.data)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            } else {
              this.dateSet()
            }
          })
    },
    getEvent() {
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  created() {
    store.dispatch('salonListesi')
  },
  beforeDestroy() {
    store.commit('OZET_GORUNUM_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card-title {
  transform: translateY(4px) !important;
}

.list-card .card-header {
  display: none !important;
}

.fc-daygrid-event b {
  white-space: normal !important;
}
</style>
